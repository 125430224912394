<template>
  <detail-layout
    title="订单详情"
    :icon="require('@/assets/images/order/dfk.png')"
    iconTitle="待付款"
  >
    <template #topContainer>
      <div
        class="top-container-explain"
        v-if="diffTime && (p.item.payDetail.payType == 1 || (p.item.payDetail.payType == 2 && p.item.payDetail.pay_type == 0))"
      >订单将在{{diffTime}}之后关闭</div>
      <div
        class="top-container-explain"
        v-if="p.item.payDetail.payType == 2"
      >需上传付款凭证</div>
    </template>
    <template
      #block3Bottom
      v-if="p.item.payDetail.pay_type != 2"
    >
      <block-line
        label="商品总额"
        :text="'￥'+p.item.order_amount"
      ></block-line>
    </template>
    <template
      #block3Bottom
      v-else
    >
      <block-line
        label="支付方式"
        :text="p.payTypeEnum[p.item.payDetail.pay_type]"
      ></block-line>
      <block-line
        label="商品总额"
        :text="'￥'+p.item.order_amount"
      ></block-line>
      <block-line
        v-if="[1,2].indexOf(p.item.payDetail.pay_type) != -1"
        label="授信金额"
        :text="'￥'+p.item.payDetail.linePayAmount"
      ></block-line>
      <block-line
        v-if="[0,2].indexOf(p.item.payDetail.pay_type) != -1"
        label="自付金额"
        :text="'￥'+p.item.payDetail.generalPayAmount"
      ></block-line>
    </template>
    <template #bottom>
      <div class="bottom-operate-placeholder"></div>
      <div class="bottom-operate">
        <!-- 不是授信支付或组合支付 或 是待付款（支付单还没生成） -->
        <div
          class="operate-button button-1"
          v-if="([1,2].indexOf(p.item.payDetail.pay_type) == -1 && p.item.payDetail.payType == 1) || (p.item.payDetail.pay_type == 0 && [1,2].indexOf(p.item.payDetail.payType) != -1)"
          @click="p.cancelOrder(p.item)"
        >取消订单</div>
        <div
          class="operate-button button-2"
          v-if="p.item.payDetail.payType == 1"
          @click="p.goPay(p.item)"
        >去付款</div>
        <div
          class="operate-button button-2"
          v-else-if="p.item.payDetail.payType == 2"
          @click="p.goUpload(p.item)"
        >上传付款凭证</div>
      </div>
    </template>
  </detail-layout>

</template>
  
  <script>
export default {

}
  </script>
  
  <style>
</style>
</template>

<script>
import DetailLayout from "./detail-layout.vue";
import BlockLine from "./block-line.vue";
export default {
  name: "orderDetail1",
  components: {
    DetailLayout,
    BlockLine,
  },
  props: {

  },
  inject: ['p'],
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {
    diffTime() {
      let date1 = this.$moment();
      let date2 = this.$moment(this.p.item.order_time).add(1, 'hours');
      let ret = date2.diff(date1, 'minute');
      if (ret <= 0) return false;
      let h = parseInt(ret / 60);
      let m = parseInt(ret % 60);
      if(h<=0){
        return m + '分钟';
      }else{
        return h + '小时' + m + '分钟';
      }
    }
  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.top-container-explain {
  margin-top: 12px;
}
.bottom-operate-placeholder {
  @include safe-area-inset-bottom-height(120px);
  margin-top: 20px;
}
.bottom-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: #ffffff;
  width: calc(100% - 60px);
  height: 120px;
  padding-left: 30px;
  padding-right: 30px;
  @include safe-area-inset-bottom;
  .operate-button {
    flex: 1;
    height: 70px;
    border-radius: 41px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      margin-left: 0;
    }
  }
  .button-1 {
    border: 1px solid #1aa3ff;
    font-size: 28px;
    font-weight: 400;
    color: #1aa3ff;
  }
  .button-2 {
    background: #1aa3ff;
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
    border: 1px solid #1aa3ff;
  }
}
</style>
