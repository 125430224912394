<template>
  <detail-layout
    title="订单详情"
    :icon="require('@/assets/images/order/dsh.png')"
    iconTitle="待收货"
  >
    <template #block3Bottom>
      <block-line
        label="支付方式"
        :text="p.payTypeEnum[p.item.payDetail.pay_type]"
      ></block-line>
      <block-line
        label="商品总额"
        :text="'￥'+p.item.order_amount"
      ></block-line>
      <block-line
        v-if="[1,2].indexOf(p.item.payDetail.pay_type) != -1"
        label="授信金额"
        :text="'￥'+p.item.payDetail.linePayAmount"
      ></block-line>
      <block-line
        v-if="[0,2].indexOf(p.item.payDetail.pay_type) != -1"
        label="自付金额"
        :text="'￥'+p.item.payDetail.generalPayAmount"
      ></block-line>
      <block-line
        label="发货时间"
        :text="p.item.delivery_time"
      ></block-line>
    </template>
    <template #bottom>
      <pay-proof></pay-proof>
      <div :class="{'bottom-operate-placeholder': true, 'bottom-operate-placeholder-1': p.item.is_invoicing == 0}"></div>
      <div :class="{'bottom-operate': true, 'bottom-operate-1': p.item.is_invoicing == 0}">
        <div
          class="operate-button button-2"
          @click="p.orderConfirm(p.item)"
        >确认收货</div>
        <!-- 开票才能下载 -->
        <download-contract v-if="p.item.is_invoicing == 1"></download-contract>
      </div>
    </template>
  </detail-layout>

</template>
<script>
import DetailLayout from "./detail-layout.vue";
import BlockLine from "./block-line.vue";
import PayProof from './pay-proof.vue';
import DownloadContract from './download-contract.vue';
export default {
  name: "orderDetail4",
  components: {
    DetailLayout,
    BlockLine,
    PayProof,
    DownloadContract,
  },
  props: {

  },
  inject: ['p'],
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.top-container-explain {
  margin-top: 12px;
  width: calc(100% - 40px);
  padding: 0 20px;
  &:last-child {
    margin-bottom: 30px;
  }
}
.top-container-explain2 {
  color: #ffebb4;
  margin-top: 12px;
  width: calc(100% - 40px);
  padding: 0 20px;
}
.pay-status {
  color: #1aa3ff;
}

.bottom-operate-placeholder {
  @include safe-area-inset-bottom-height(266px);
  margin-top: 20px;
}
.bottom-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: calc(100% - 60px);
  height: 266px;
  padding-left: 30px;
  padding-right: 30px;
  @include safe-area-inset-bottom;
  .operate-button {
    width: 100%;
    height: 88px;
    border-radius: 41px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child{
      margin-bottom: 0px;
    }
  }
  .button-2 {
    background: #1aa3ff;
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
    border: 1px solid #1aa3ff;
  }
}
.bottom-operate-placeholder-1{
  @include safe-area-inset-bottom-height(140px);
}
.bottom-operate-1{
  height: 140px;
}
</style>
