<template>
  <div class="detail-container">
    <!-- 待付款 -->
    <detail1 v-if="item.order_status == 0"></detail1>
    <!-- 付款中 -->
    <detail2 v-if="item.order_status == 1"></detail2>
    <!-- 待发货 -->
    <detail3 v-if="item.order_status == 2 || item.order_status == 3"></detail3>
    <!-- 待收货 -->
    <detail4 v-if="item.order_status == 4"></detail4>
    <!-- 已完成 -->
    <detail5 v-if="item.order_status == 5"></detail5>
    <!-- 已取消 -->
    <detail6 v-if="item.order_status == 6"></detail6>
  </div>
</template>

<script>
import { orderDetail, orderCancel, orderConfirm } from "@/api/order.js";
import Detail1 from './components/detail1.vue';
import Detail2 from './components/detail2.vue';
import Detail3 from './components/detail3.vue';
import Detail4 from './components/detail4.vue';
import Detail5 from './components/detail5.vue';
import Detail6 from './components/detail6.vue';
export default {
  name: "orderDetail",
  components: {
    Detail1,
    Detail2,
    Detail3,
    Detail4,
    Detail5,
    Detail6,
  },
  provide() {
    return {
      p: this,
    };
  },
  inject: ['_app'],
  data() {
    return {
      item: {},
      payTypeEnum: {
        0: '普通支付',
        1: '授信支付',
        2: '组合支付',
      },
    };
  },
  mounted() {
    if (this.$route.params != undefined && this.$route.params.id != undefined) {
      this.id = this.$route.params.id;
    }
    this.getItem(true);
  },
  methods: {
    getItem(init=false) {
      orderDetail({ id: this.id }).then(res => {
        if (res.code == 0) {
          this.item = res.data;
        } else {
          this.$toast({
            message: res.msg, onClose: () => {
              if(init) this.$back();
            }
          })
        }
      })
    },
    //取消订单
    cancelOrder(item) {
      orderCancel({ id: item.id }).then(res => {
        if (res.code == 0) {
          this.getItem();
          this._app.reload('order');  //刷新订单列表
        } else {
          this.$toast(res.msg);
        }
      })
    },
    //去付款
    goPay(item) {
      this.$router.push('/pay/' + item.id);
    },
    //上传支付凭证
    goUpload(item) {
      this.$router.push('/pay/voucher/' + item.id);
    },
    //确认收货
    orderConfirm(item) {
      orderConfirm({ id: item.id }).then(res => {
        if (res.code == 0) {
          this.getItem();
          this._app.reload('order');  //刷新订单列表
        } else {
          this.$toast(res.msg);
        }
      })
    },
  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.detail-container {
}
</style>
