<template>
  <div class="download" @click="download">
    销售合同下载 <img class="download-icon" :src="require('@/assets/images/icon/download.png')" />
  </div>
</template>

<script>
import { downloadContract } from "@/api/order.js";
import { downloadFileUrl } from "@/lib/download.js";
import wx from 'jweixin-npm'
export default {
  inject: ['p'],
  methods:{
    download(){
      downloadContract({id: this.p.item.id}).then(async res=>{
        if(res.code == 0){
          let url = res.data;
          // 判断所在环境
          let ua = window.navigator.userAgent.toLowerCase();
          if (ua.match(/micromessenger/i) == 'micromessenger') {
            // 打开微信特定页面，然后下载文件
            wx.miniProgram.getEnv(async function(res) {
              if(res.miniprogram){
                wx.miniProgram.navigateTo({
                  url: `/packageA/pages/download/download?url=${encodeURIComponent(url)}`
                })
              }else{
                await downloadFileUrl(url, "销售合同"+url.substring(url.lastIndexOf('.')));
              }
            })
            // console.log('是微信客户端')
          } else {
            // h5端直接使用a标签下载
            await downloadFileUrl(url, "销售合同"+url.substring(url.lastIndexOf('.')));
            // console.log('不是微信客户端')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.download {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1aa3ff;
  font-size: 36px;
  font-weight: 400;
  color: #1aa3ff;
  height: 88px;
  border-radius: 44px;
  .download-icon{
    width: 36px;
    height: 36px;
    margin-left: 27px;
  }
}
</style>