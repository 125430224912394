import axios from 'axios'
// import JSZip from 'jszip'
// import FileSaver from 'file-saver'

export async function downloadFileUrl(link, fileName) {
  let url = link
  // if(link.substr(0,7).toLowerCase() == "http://" || link.substr(0,8).toLowerCase() == "https://") {
  //   let data = await getFile(link);
  //   url = window.URL.createObjectURL(new Blob([data]));
  // }
  const elink = document.createElement('a')
  elink.href = url
  elink.setAttribute('download', fileName)
  elink.style.display = 'none'
  elink.setAttribute('target', '_blank');
  document.body.appendChild(elink)
  elink.click()
  document.body.removeChild(elink)
}

// /**
//  * 批量下载文件，生成压缩包
//  * @param {*} urls 下载文字路径数组
//  */
// export function batchDownload(urls, fileName) {
//   const zip = new JSZip()
//   const cache = {}
//   const promises = []
//   urls.forEach(item => {
//     const promise = getFile(item)
//       .then(data => {
//       const arr_name = item.split("/")
//       const file_name = arr_name[arr_name.length - 1]
//       zip.file(file_name, data, { binary: true })
//       cache[file_name] = data
//       })
//     promises.push(promise)
//   })
 
//   Promise.all(promises)
//   .then(() => {
//     zip.generateAsync({type:"blob"})
//       .then(content => { // 生成二进制流
//         FileSaver.saveAs(content, fileName + '.zip')
//       })
//   })
//  }


/**
 * 
 * @param {*} url 文件url 
 * @returns 
 */
function getFile(url) {
  return new Promise((resolve, reject) => {
    axios({
      method:'get',
      url,
      responseType: 'arraybuffer'
    }).then(data => {
        resolve(data.data)
      })
      .catch(error => {
        reject(error.toString())
      })
    })
 }
