<template>
  <detail-layout
    title="订单详情"
    :icon="require('@/assets/images/order/fkz.png')"
    iconTitle="付款中"
  >
    <template #topContainer>
      <template v-if="p.item.payDetail.generalPayStatus != 3 && p.item.payDetail.linePayStatus != 3">
        <div class="top-container-explain">
          <template v-if="p.item.payDetail.pay_type == 0">{{generalPayStatusEnum[p.item.payDetail.generalPayStatus]}}</template>
          <template v-if="p.item.payDetail.pay_type == 1">{{linePayStatusEnum[p.item.payDetail.linePayStatus]}}</template>
          <template v-if="p.item.payDetail.pay_type == 2">{{linePayStatusEnum[p.item.payDetail.linePayStatus]}}，{{generalPayStatusEnum[p.item.payDetail.generalPayStatus]}}</template>
        </div>
        <!-- <div class="top-container-explain">如有疑问请致电联系平台客服：{{config.ServiceTel}}</div> -->
      </template>
      <template v-else>
        <div class="top-container-explain2">
          <template v-if="p.item.payDetail.pay_type == 0">
            {{generalPayStatusEnum[p.item.payDetail.generalPayStatus]}}<template v-if="p.item.payDetail.generalFailedReason">（{{p.item.payDetail.generalFailedReason}}）</template>
          </template>
          <template v-if="p.item.payDetail.pay_type == 1">
            {{linePayStatusEnum[p.item.payDetail.linePayStatus]}}<template v-if="p.item.payDetail.linePayFailedReason">（{{p.item.payDetail.linePayFailedReason}}）</template>
          </template>
          <template v-if="p.item.payDetail.pay_type == 2">
            {{linePayStatusEnum[p.item.payDetail.linePayStatus]}}<template v-if="p.item.payDetail.linePayFailedReason">（{{p.item.payDetail.linePayFailedReason}}）</template>，
            {{generalPayStatusEnum[p.item.payDetail.generalPayStatus]}}<template v-if="p.item.payDetail.generalFailedReason">（{{p.item.payDetail.generalFailedReason}}）</template>
          </template>
        </div>
        <!-- <div class="top-container-explain">如有疑问请致电联系平台客服：{{config.ServiceTel}}</div> -->
      </template>
    </template>
    <template #block3Bottom>
      <block-line
        label="支付方式"
        :text="p.payTypeEnum[p.item.payDetail.pay_type]"
      ></block-line>
      <block-line
        label="商品总额"
        :text="'￥'+p.item.order_amount"
      ></block-line>
      <block-line
        v-if="[1,2].indexOf(p.item.payDetail.pay_type) != -1"
        label="授信金额"
        :text="'￥'+p.item.payDetail.linePayAmount"
      ></block-line>
      <block-line
        v-if="[0,2].indexOf(p.item.payDetail.pay_type) != -1"
        label="自付金额"
        :text="'￥'+p.item.payDetail.generalPayAmount"
      ></block-line>

      <block-line
        label="授信支付时间"
        v-if="[1,2].indexOf(p.item.payDetail.pay_type) != -1 && p.item.payDetail.linePayTimes"
        :text="p.item.payDetail.linePayTimes"
      >
        <!-- <template #text>
          <span class="pay-status">{{p.item.payDetail.linePayTimes}}</span>
        </template> -->
      </block-line>
      <block-line
        label="自付支付时间"
        v-if="[0,2].indexOf(p.item.payDetail.pay_type) != -1 && p.item.payDetail.generalPayTimes"
        :text="p.item.payDetail.generalPayTimes"
      >
        <!-- <template #text>
          <span class="pay-status">{{p.item.payDetail.generalPayTimes}}</span>
        </template> -->
      </block-line>
    </template>
    <template #bottom>
      <pay-proof></pay-proof>
      <div class="bottom-placeholder"></div>
    </template>
  </detail-layout>

</template>
<script>
import DetailLayout from "./detail-layout.vue";
import BlockLine from "./block-line.vue";
import PayProof from './pay-proof.vue';
import config from '@/config/index.js';
export default {
  name: "orderDetail2",
  components: {
    DetailLayout,
    BlockLine,
    PayProof,
  },
  props: {

  },
  inject: ['p'],
  data() {
    return {
      config,
      linePayStatusEnum: { 1: '授信支付：银行拨款中', 2: '授信支付：银行已拨款', 3: '授信支付：银行拨款失败' },
      generalPayStatusEnum: { 1: '自付支付：凭证审核中', 2: '自付支付：凭证审核通过', 3: '自付支付：凭证审核失败' },
    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.top-container-explain {
  margin-top: 12px;
  width: calc(100% - 40px);
  padding: 0 20px;
  &:last-child {
    margin-bottom: 30px;
  }
}
.top-container-explain2 {
  color: #ffebb4;
  margin-top: 12px;
  width: calc(100% - 40px);
  padding: 0 20px;
}
.pay-status {
  color: #1aa3ff;
}
.bottom-placeholder {
  @include safe-area-inset-bottom(22px);
}
</style>
