<template>
  <div class="pay-proof-block" v-if="p.item.payDetail.pay_voucher">
    <div class="block-title">付款凭证</div>
    <van-image
      class="pay-proof-img"
      fit="contain"
      :src="p.item.payDetail.pay_voucher"
      @click="ImagePreview([p.item.payDetail.pay_voucher])"
    />
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "payProof",
  components: {

  },
  props: {

  },
  inject: ['p'],
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    ImagePreview,
  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.pay-proof-block {
  background: #ffffff;
  border-radius: 10px;
  padding: 19px 34px 29px 34px;
  margin: 20px;
  margin-bottom: 0;
  .block-title {
    font-size: 30px;
    font-weight: bold;
    color: #4f4f4f;
  }
  .pay-proof-img {
    margin-top: 10px;
    height: 100px;
    width: 100px;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>
