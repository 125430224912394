<template>
  <div class="block2-line">
    <div class="block2-line-label">
      <slot name="label">{{label}}</slot>
    </div>
    <div class="block2-line-text">
      <slot name="text">{{text}}</slot>
      <slot name="operate"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "block-line",
  components: {

  },
  props: {
    label: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    }
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.block2-line {
  display: flex;
  margin-top: 20px;
  &:first-child {
    margin-top: 0px;
  }
  .block2-line-label {
    width: 314px;
    flex-shrink: 0;
    font-size: 28px;
    font-weight: 400;
    color: #999999;
  }
  .block2-line-text {
    flex: 1;
    text-align: right;
    font-size: 28px;
    font-weight: 400;
    color: #666666;
    word-break:break-all;
  }
}
</style>
