<template>
  <detail-layout
    title="订单详情"
    :icon="require('@/assets/images/order/yqx.png')"
    iconTitle="已取消"
  >
    <template #block3Bottom>
      <block-line
        label="商品总额"
        :text="'￥'+p.item.order_amount"
      ></block-line>
      <block-line
        label="订单取消时间"
        :text="p.item.cancel_time"
      ></block-line>
    </template>
    <template #bottom>
      <div class="bottom-placeholder"></div>
    </template>
  </detail-layout>

</template>
<script>
import DetailLayout from "./detail-layout.vue";
import BlockLine from "./block-line.vue";
export default {
  name: "orderDetail6",
  components: {
    DetailLayout,
    BlockLine,
  },
  props: {

  },
  inject: ['p'],
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.bottom-placeholder {
  @include safe-area-inset-bottom(22px);
}
</style>
