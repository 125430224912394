<template>
  <detail-layout
    title="订单详情"
    :icon="require('@/assets/images/order/dfh.png')"
    iconTitle="待发货"
  >
    <template #block3Bottom>
      <block-line
        label="支付方式"
        :text="p.payTypeEnum[p.item.payDetail.pay_type]"
      ></block-line>
      <block-line
        label="商品总额"
        :text="'￥'+p.item.order_amount"
      ></block-line>
      <block-line
        v-if="[1,2].indexOf(p.item.payDetail.pay_type) != -1"
        label="授信金额"
        :text="'￥'+p.item.payDetail.linePayAmount"
      ></block-line>
      <block-line
        v-if="[0,2].indexOf(p.item.payDetail.pay_type) != -1"
        label="自付金额"
        :text="'￥'+p.item.payDetail.generalPayAmount"
      ></block-line>
      <!-- <block-line
        label="发货时间"
        :text="p.item.delivery_time"
      ></block-line> -->
    </template>
    <template #bottom>
      <pay-proof></pay-proof>
      <div class="bottom-placeholder" v-if="p.item.is_invoicing == 0"></div>
      <!-- 开票的才能下载 -->
      <template v-else>
        <div class="bottom-operate-placeholder"></div>
        <div class="bottom-operate">
          <download-contract></download-contract>
        </div>
      </template>
    </template>
  </detail-layout>

</template>
<script>
import DetailLayout from "./detail-layout.vue";
import BlockLine from "./block-line.vue";
import PayProof from './pay-proof.vue';
import DownloadContract from './download-contract.vue';
export default {
  name: "orderDetail3",
  components: {
    DetailLayout,
    BlockLine,
    PayProof,
    DownloadContract,
  },
  props: {

  },
  inject: ['p'],
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.top-container-explain {
  margin-top: 12px;
  width: calc(100% - 40px);
  padding: 0 20px;
  &:last-child {
    margin-bottom: 30px;
  }
}
.top-container-explain2 {
  color: #ffebb4;
  margin-top: 12px;
  width: calc(100% - 40px);
  padding: 0 20px;
}
.pay-status {
  color: #1aa3ff;
}
.bottom-placeholder {
  @include safe-area-inset-bottom(22px);
}
.bottom-operate-placeholder {
  @include safe-area-inset-bottom-height(140px);
  margin-top: 20px;
}
.bottom-operate {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: #ffffff;
  width: calc(100% - 60px);
  height: 140px;
  padding-left: 30px;
  padding-right: 30px;
  @include safe-area-inset-bottom;
}
</style>
