<template>
  <div class="container">
    <navbar :title="title"></navbar>
    <div class="top-container">
      <div class="top-title-container">
        <img
          class="top-icon"
          :src="icon"
        />
        <span class="top-title">{{iconTitle}}</span>
      </div>
      <slot name="topContainer">
        <!-- 顶部容器 -->
      </slot>
    </div>
    <div class="block">
      <div class="factory-name">{{p.item.supplier_name}}</div>
      <div class="toy-item">
        <van-image
          class="toy-img"
          fit="contain"
          :src="p.item.toy_thumbnail"
        ></van-image>
        <div class="toy-content">
          <div class="toy-name">{{p.item.toy_name}}</div>
          <div class="toy-number">型号：{{p.item.toy_article_number}}</div>
          <div class="toy-price-num">
            <div class="toy-price">
              <div class="price-unit">￥</div>
              <div class="price-num">{{p.item.purchase_price}}</div>
              <div class="num-unit">/{{p.item.quote_unit}}</div>
            </div>
            <div class="toy-num">×{{p.item.purchase_quantity}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block2">
      <div class="block2-title">配送信息</div>
      <div class="block2-content">
        <block-line
          label="期望配送时间"
          :text="p.item.expected_delivery_time"
        ></block-line>
        <block-line
          label="配送地址"
          :text="p.item.detailed_area+p.item.address"
        ></block-line>
        <block-line
          label="联系人"
          :text="p.item.consignee+' '+p.item.mobilephone"
        ></block-line>
      </div>
    </div>
    <div class="block3">
      <block-line
        label="订单编号"
        :text="p.item.order_number"
      >
        <template #operate><span
            class="copy-button"
            @click="$copy(p.item.order_number)"
          >复制</span></template>
      </block-line>
      <block-line
        label="下单时间"
        :text="p.item.order_time"
      ></block-line>
      <block-line
        label="是否开票"
        :text="p.item.is_invoicing ? '是' : '否'"
      ></block-line>
      <slot name="block3Bottom">
        <!-- 块3的底部 -->
      </slot>
    </div>
    <slot name="bottom">
      <!-- 整体的底部 -->
    </slot>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import BlockLine from "./block-line.vue";
export default {
  name: "layout",
  components: {
    Navbar,
    BlockLine,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: '',
    iconTitle: {
      type: String,
      default: '',
    },
  },
  inject: ['p'],
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.container {
  .top-container {
    background: #1aa3ff;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    .top-title-container {
      display: flex;
      align-items: center;
      .top-icon {
        height: 48px;
        width: 48px;
      }
      .top-title {
        font-size: 36px;
        font-weight: bold;
        color: #fbfce4;
        margin-left: 20px;
      }
    }
  }
  .block {
    margin: 20px;
    margin-bottom: 0px;
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 30px;
    .factory-name {
      font-size: 36px;
      font-weight: bold;
      color: #4f4f4f;
    }
    .toy-item {
      display: flex;
      margin-top: 25px;
      .toy-img {
        width: 180px;
        height: 180px;
        border-radius: 10px;
        border: 1px solid #eeeeee;
        overflow: hidden;
        margin-right: 30px;
      }
      .toy-content {
        flex: 1;
        overflow: hidden;
        .toy-name {
          font-size: 34px;
          line-height: 48px;
          font-weight: 500;
          color: #4f4f4f;
          margin-top: 6px;
          @include not-wrap;
        }
        .toy-number {
          font-size: 28px;
          line-height: 40px;
          font-weight: 400;
          color: #999999;
          margin-top: 8px;
        }
        .toy-price-num {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          align-items: flex-end;
          .toy-price {
            display: flex;
            color: #f33636;
            align-items: flex-end;
            .price-unit {
              font-size: 28px;
            }
            .price-num {
              font-size: 40px;
              line-height: 42px;
              font-weight: bold;
              color: #f33636;
            }
            .num-unit {
              font-size: 22px;
              font-weight: 400;
              color: #999999;
            }
          }
          .toy-num {
            font-size: 28px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
  .block2 {
    margin: 20px;
    margin-bottom: 0px;
    background: #ffffff;
    border-radius: 10px;
    .block2-title {
      font-size: 30px;
      font-weight: bold;
      color: #4f4f4f;
      padding: 20px 30px 14px 30px;
      border-bottom: 1px solid #eeeeee;
    }
    .block2-content {
      padding: 16px 30px 24px 30px;
      .block2-line {
        display: flex;
        margin-top: 20px;
        &:first-child {
          margin-top: 0px;
        }
        .block2-line-label {
          width: 314px;
          flex-shrink: 0;
          font-size: 28px;
          font-weight: 400;
          color: #999999;
        }
        .block2-line-text {
          flex: 1;
          text-align: right;
          font-size: 28px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
  .block3 {
    margin: 20px;
    margin-bottom: 0px;
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 30px;
    .copy-button {
      font-weight: 400;
      color: #1aa3ff;
      margin-left: 10px;
    }
  }
}
</style>
